var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("maca-titulo-pagina", {
        attrs: { icono: "icon-user", nombrePagina: "Beneficiarios" }
      }),
      _c(
        "el-card",
        {
          staticStyle: { margin: "2%" },
          attrs: { "body-style": { padding: "1.5%" } }
        },
        [
          _c(
            "div",
            { attrs: { slot: "header" }, slot: "header" },
            [
              _c(
                "div",
                { staticStyle: { "margin-left": "0px" } },
                [
                  _c("el-input", {
                    staticClass: "buscador tamañoInputBucar",
                    attrs: {
                      "prefix-icon": "el-icon-search",
                      placeholder: "Buscar"
                    },
                    model: {
                      value: _vm.filtroNombre,
                      callback: function($$v) {
                        _vm.filtroNombre = $$v
                      },
                      expression: "filtroNombre"
                    }
                  }),
                  _c("el-button", {
                    staticClass: "botonmasIcono",
                    staticStyle: { "margin-left": "0.2rem" },
                    attrs: {
                      icon: "el-icon-refresh",
                      type: "primary",
                      circle: ""
                    },
                    on: {
                      click: function($event) {
                        return _vm.recargarTabla()
                      }
                    }
                  })
                ],
                1
              ),
              _c("el-button", {
                staticClass: "botonmasIcono ocultarMostrarItem",
                staticStyle: { "margin-left": "0.2rem" },
                attrs: { icon: "el-icon-plus", type: "primary", circle: "" },
                on: {
                  click: function($event) {
                    return _vm.$refs.modalNuevo.abrir()
                  }
                }
              })
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "maca-datatable",
                {
                  attrs: {
                    url: _vm.urlTabla,
                    params: _vm.paramsTabla,
                    actualizar: _vm.actualizarTabla,
                    bloquear: _vm.bloquearTabla
                  },
                  on: {
                    "update:actualizar": function($event) {
                      _vm.actualizarTabla = $event
                    },
                    "update:bloquear": function($event) {
                      _vm.bloquearTabla = $event
                    }
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "descripcion",
                      label: "Clave",
                      "min-width": 15
                    }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "valor", label: "Valor %", "min-width": 10 }
                  }),
                  _c("el-table-column", {
                    attrs: { width: "70" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(props) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "warning",
                                  circle: "",
                                  disabled: !_vm.$store.getters.tienePermiso(
                                    "M_USE"
                                  )
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.$refs.modalModificar.abrir(
                                      props.row.id
                                    )
                                  }
                                }
                              },
                              [_c("i", { staticClass: "el-icon-edit" })]
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _c("modal-nuevo", {
        ref: "modalNuevo",
        on: {
          "actualizar-tabla": function($event) {
            _vm.actualizarTabla = true
          }
        }
      }),
      _c("modal-modificar", {
        ref: "modalModificar",
        on: {
          "actualizar-tabla": function($event) {
            _vm.actualizarTabla = true
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }