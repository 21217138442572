<template>
  <div>
    <maca-modal
      titulo="Nueva Configuracion"
      :impedirClose="impedirClose"
      ref="modal"
    >
      <el-card
        :body-style="{ padding: '2%' }"
        style="margin: 1.5%"
        shadow="never"
        class="mostrarOcultarCard"
      >
        <el-form
          :model="form"
          ref="form"
          :rules="formRules"
          label-position="top"
          label-width="130px"
        >
          <el-form-item label="Clave" prop="descripcion">
            <el-input maxlength="30" v-model="form.descripcion" disabled></el-input>
          </el-form-item>

          <el-form-item label="Valor" prop="valor">
            <el-input maxlength="30" v-model="form.valor"></el-input>
          </el-form-item>

          <el-form-item>
            <maca-boton-guardar :onSubmit="onSubmit"></maca-boton-guardar>
          </el-form-item>
        </el-form>
      </el-card>
      <el-card
        :body-style="{ padding: '2%' }"
        style="margin: 1.5%"
        shadow="never"
        class="ocultarMostrarCard"
      >
        <el-form
          :model="form"
          ref="form"
          :rules="formRules"
          label-position="left"
          label-width="130px"
        >
          <el-form-item label="Clave" prop="clave">
            <el-input maxlength="30" v-model="form.clave"></el-input>
          </el-form-item>
          <el-form-item label="Valor" prop="valor">
            <el-input maxlength="30" v-model="form.valor"></el-input>
          </el-form-item>

          <el-form-item label="Tipo de dato" prop="tipoDato">
            <el-input maxlength="30" v-model="form.tipoDato"></el-input>
          </el-form-item>

          <el-form-item label="Descripcion" prop="descripcion">
            <el-input maxlength="30" v-model="form.descripcion"></el-input>
          </el-form-item>

          <el-form-item>
            <maca-boton-guardar :onSubmit="onSubmit"></maca-boton-guardar>
          </el-form-item>
        </el-form>
      </el-card>
    </maca-modal>
  </div>
</template>

<style scoped>
</style>

<script>
export default {
  name: "nuevo-usuario",
  data() {
    return {
      form: {
        clave: null,
        valor: null,
        tipoDato: null,
        descripcion: null,
      },
      impedirClose: false,
      persona: null,
      idPadre: 0,
      urlProvincia: "/provincia/obtenerTodosSelect",

      formRules: {
           clave: [
          {
            required: true,
            message: "Por favor introduzca la clave.",
            trigger: "change",
          },
        ],
        valor: [
          {
            required: true,
            message: "Por favor introduzca el valor.",
            trigger: "change",
          },
        ],
        tipoDato: [
          {
            required: true,
            message: "Por favor  introduzca el tipo de dato.",
            trigger: "change",
          },
        ],
        descripcion: [
          {
            required: true,
            message: "Por favor  introduzca la descripción.",
            trigger: "change",
          },
        ],
      },
    };
  },
  methods: {
    abrir(idPadre) {
      this.$refs.modal.abrir();

      if (this.$refs.form) {
        this.$refs.form.resetFields();
      }
      this.idPadre = idPadre;
      this.persona = null;
    },
    cerrar() {
      this.$refs.modal.cerrar();
    },
    onSubmit(afterSubmit) {
      this.$refs.form.validate(async (formOk, _) => {
        if (!formOk) {
          afterSubmit();
          return false;
        }

        this.impedirClose = true;

        let postOk = await this.postApi();

        this.impedirClose = false;
        afterSubmit();

        if (postOk) {
          this.$emit(this.$maca.eventStrings._actualizarTabla);
          this.cerrar();
        }
      });
    },
    async postApi() {
      // Hacer Post
      let params = {
        clave:this.form.clave,
        valor: this.form.valor,
        tipoDato: this.form.tipoDato,
        descripcion: this.form.descripcion,
      };

      let respuestaApi = await this.$maca.api.post(
        "/configuracion/crear",
        params
      );
      //

      // Procesar respuesta
      if (respuestaApi.estado == 1) {
        this.$message({
          message: "¡Departamento creado con éxito!",
          type: "success",
        });

        this.$emit("listo");
        return true;
      } else {
        this.$message({
          message: respuestaApi.excepcion,
          type: "error",
          showClose: true,
          duration: 0,
        });
      }
      //

      return false;
    },
  },
  watch: {
    persona() {
      if (this.persona == null) {
        this.form.personaID = null;
        return;
      }

      this.form.nombre = this.persona.nombre;
      this.form.apellido = this.persona.apellido;
      this.form.password = this.persona.dni;
    },
  },
};
</script>

<style scoped>
.ocultarMostrarCard {
  display: none;
}
.mostrarOcultarCard {
  display: block;
}
@media screen and (max-device-width: 645px) {
  .ocultarMostrarCard {
    display: block;
  }
  .mostrarOcultarCard {
    display: none;
  }
}
</style>