var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "maca-modal",
        {
          ref: "modal",
          attrs: {
            titulo: "Modificar Configuración",
            impedirClose: _vm.impedirClose
          }
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.cargando,
                  expression: "cargando"
                }
              ],
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.formRules,
                "label-position": "left",
                "label-width": "130px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "Descripcion", prop: "descripcion" } },
                [
                  _c("el-input", {
                    attrs: { maxlength: "30", disabled: "" },
                    model: {
                      value: _vm.form.descripcion,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "descripcion", $$v)
                      },
                      expression: "form.descripcion"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Valor", prop: "valor" } },
                [
                  _c("el-input", {
                    attrs: { maxlength: "30" },
                    model: {
                      value: _vm.form.valor,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "valor", $$v)
                      },
                      expression: "form.valor"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("maca-boton-guardar", {
                    attrs: { onSubmit: _vm.onSubmit }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }