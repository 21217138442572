<template>
  <div>
    <maca-titulo-pagina
      icono="icon-user"
      nombrePagina="Beneficiarios"
    ></maca-titulo-pagina>
    <el-card :body-style="{ padding: '1.5%' }" style="margin: 2%">
      <div slot="header">
        <div style="margin-left: 0px">
          <el-input
            class="buscador tamañoInputBucar"
            prefix-icon="el-icon-search"
            placeholder="Buscar"
            v-model="filtroNombre"
          >
          </el-input>
          <el-button
            class="botonmasIcono"
            icon="el-icon-refresh"
            type="primary"
            circle
            style="margin-left: 0.2rem"
            @click="recargarTabla()"
          ></el-button>
        </div>

        <el-button
          class="botonmasIcono ocultarMostrarItem"
          icon="el-icon-plus"
          type="primary"
          circle
          style="margin-left: 0.2rem"
          @click="$refs.modalNuevo.abrir()"
        ></el-button>
      </div>

      <div>
        <maca-datatable
          :url="urlTabla"
          :params="paramsTabla"
          :actualizar.sync="actualizarTabla"
          :bloquear.sync="bloquearTabla"
        >
          <el-table-column
            prop="descripcion"
            label="Clave"
            :min-width="15"
          >
          </el-table-column>
            <el-table-column
            prop="valor"
            label="Valor %"
            :min-width="10"
          >
          </el-table-column>

          <el-table-column width="70">
            <template slot-scope="props">
              <el-button
                type="warning"
                circle
                @click="$refs.modalModificar.abrir(props.row.id)"
                :disabled="!$store.getters.tienePermiso('M_USE')"
              >
                <i class="el-icon-edit"></i>
              </el-button>
            </template>
          </el-table-column>
        </maca-datatable>
      </div>
    </el-card>
    <modal-nuevo
      ref="modalNuevo"
      @actualizar-tabla="actualizarTabla = true"
    ></modal-nuevo>
    <modal-modificar
      ref="modalModificar"
      @actualizar-tabla="actualizarTabla = true"
    ></modal-modificar>
  </div>
</template>
<style scoped>
.ocultarMostrarItem {
  display: none;
}
.mostrarOcultarItem {
  display: block;
}
@media screen and (max-device-width: 560px) {
  .ocultarMostrarItem {
    display: block;
  }
  .mostrarOcultarItem {
    display: none;
  }
}

.tamañoInputBucar {
  width: 15rem;
}
@media screen and (max-device-width: 385px) {
  .tamañoInputBucar {
    width: 12rem;
  }
}
</style>
<script>
import ModalNuevo from "./modales/nuevo";
import ModalModificar from "./modales/modificar";

export default {
  name: "usuario",
  components: {
    ModalNuevo,
    ModalModificar,
  },
  data() {
    return {
      urlTabla: "/configuracion/obtenerTodos",
      paramsTabla: {},
      actualizarTabla: true,
      bloquearTabla: true,

      filtroNombre: null,
    };
  },
  methods: {
    calcularEdad(fechaRecibida) {
      let fecha1 = new Date(fechaRecibida);
      let fecha2 = new Date();
      var edad = fecha2.getFullYear() - fecha1.getFullYear();
      var m = fecha2.getMonth() - fecha1.getMonth();

      if (m < 0 || (m === 0 && fecha2.getDate() < fecha1.getDate())) {
        edad--;
        if (fecha2.getFullYear() == fecha1.getFullYear()) {
          edad++;
        }
      }
      this.yaEligioFecha = true;
      return edad;
    },
    async eliminar(id) {
      let confirmado = await this.$confirm(
        "Confirme que desea eliminar el beneficiario."
      );

      if (!confirmado) {
        return;
      }

      this.bloquearTabla = true;

      // Hacer post
      let params = { id: id };

      let respuestaApi = await this.$maca.api.post(
        "/configuracion/eliminar",
        params
      );
      //

      this.actualizarTabla = true;

      // Procesar respuesta
      if (respuestaApi.estado == 1) {
        this.$message({
          message: "¡Configuración borrado con éxito!",
          type: "success",
        });
      } else {
        this.$message({
          message: respuestaApi.excepcion,
          type: "error",
          showClose: true,
          duration: 0,
        });
      }
      //
    },
    async toogleBloqueado(fila) {
      this.bloquearTabla = true;

      // Hacer post
      let params = { id: fila.id };
      let respuestaApi = null;

      if (fila.bloqueado) {
        respuestaApi = await this.$maca.api.post("/user/bloquear", params);
      } else {
        respuestaApi = await this.$maca.api.post("/user/desbloquear", params);
      }
      //

      this.actualizarTabla = true;

      // Procesar respuesta
      if (respuestaApi.estado != 1) {
        this.$message({
          message: respuestaApi.excepcion,
          type: "error",
          showClose: true,
          duration: 0,
        });
      }
      //
    },
  },
  watch: {
    filtroNombre: function (n, o) {
      this.paramsTabla.nombre = this.filtroNombre;
      this.actualizarTabla = true;
    },
  },
};
</script>
